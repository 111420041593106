var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav-main" }, [
    _c("div", { staticClass: "left-click", on: { click: _vm.exit } }, [
      _c("span", { staticClass: "left-icon iconfont icon-back" }),
      _c("span", { staticClass: "left-text" }, [
        _vm._v(_vm._s(_vm.$route["path"] === "/index" ? "退出" : "返回")),
      ]),
    ]),
    _c("div", { staticClass: "nav-title" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$route.query.formal == "1"
              ? _vm.$route.meta["titleOr"]
              : _vm.$route.meta["title"]
          ) +
          " "
      ),
    ]),
    _c("div", { staticClass: "right-click" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }