<template>
  <div class="nav-main">
    <div class="left-click" @click="exit">
      <!-- <svg-icon class="left-icon" icon-class="Black" /> -->
      <span class="left-icon iconfont icon-back" />
      <span class="left-text">{{ $route['path'] === '/index' ? '退出' : '返回' }}</span>
    </div>
    <div class="nav-title">
      {{ $route.query.formal == '1' ? $route.meta['titleOr'] : $route.meta['title'] }}
    </div>
    <div class="right-click" />
  </div>
</template>
<script>
import { Dialog } from 'vant'
export default {
  name: 'NavBar',
  components: {},
  props: {},

  data() {
    return {}
  },
  computed: {},
  watch: {
    // $route(to, from) {
    //   console.log(to)
    // },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    exit() {
      if (this.$route.path === '/index') {
        window.jme.browser.close({
          callback: function () {},
        })
      } else if (this.$route.path === '/simulation' && this.$route.query.formal == '1') {
        // 正式竞赛 提醒
        Dialog.confirm({
          title: '提示',
          message: '考试已开始倒计时，退出重新进入考试，所有试题需要重做',
        })
          .then(() => {
            this.$router.back()
          })
          .catch(() => {
            // on cancel
          })
      } else {
        this.$router.back()
      }
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.nav-main {
  background-color: white;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //   justify-content: flex-start;
  align-items: center;
  color: #000;

  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .nav-title {
    opacity: 0.9;
  }
  .left-click {
    // padding-left: 16px;
    width: 100px;
    // margin-right: 100px;
    font-size: 14px;
    .left-icon {
      margin-left: 16px;
      color: #000;
    }
    .left-text {
      margin-left: 5px;
    }
  }
  .right-click {
    width: 100px;
  }
}
</style>
